import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CompanyIcon from './CompanyIcon.jsx';
import './SignInCert.css';
import { MuiFileInput } from 'mui-file-input'
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import JSZip from 'jszip';
import Link from '@mui/material/Link';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import * as PDFJS from "pdfjs-dist/build/pdf";
// import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import timesFont from './times.ttf';

const dateFormatRu = new Intl.DateTimeFormat("ru");
const cadesplugin = window.cadesplugin;
const fErrorHandler = er => {
  alert(er.message || er);
  // console.log(er);
}

function App() {

  const [certsList, setCertsList] = useState([]);
  const [selectedCert, setSelectedCert] = useState({ bValidTime: true });
  const [submitOn, setSubmitOn] = useState(true);
  const [bHasFile, setHasFile] = useState(false);
  const [bHasCert, setHasCert] = useState(false);
  const [loading, setLoading] = useState();
  const [selectedFiles, setSelectedFiles] = useState([])
  const [bDetached, setDetached] = useState(true);

  const fTest = ev => {
    // console.log(selectedFiles.length && selectedFiles[0].name.toLowerCase().endsWith(".pdf"));
    // cadesplugin.CreateObjectAsync("CAdESCOM.About").then(oCadesAbout => console.log(oCadesAbout));
    // fGetCades(async pCades => {
    //   var oCadesAbout = await pCades.CreateObjectAsync("CAdESCOM.About");
    //   var oLicense = await pCades.CreateObjectAsync("CAdESCOM.CPLicense");
    //   var licType = await oLicense.Type();
    //   var validTo = await oLicense.ValidTo(pCades.CADESCOM_PRODUCT_TSP);
    //   var serialNumber = await oLicense.SerialNumber();
    //   console.log(serialNumber);
    // })
    if (selectedFiles.length && selectedFiles[0].name.toLowerCase().endsWith(".pdf")) {
      const oFReader = new FileReader();
      oFReader.onload = async oFREvent => {
        const pdfArrayBuffer = oFREvent.target.result;
        const pdfDoc = await PDFDocument.load(pdfArrayBuffer);
        pdfDoc.registerFontkit(fontkit);
        const page = pdfDoc.getPages()[0];
        const { width, height } = page.getSize();
        const pdfFrame = document.getElementById('pdf');
        pdfFrame.style.width = Math.floor(width * 1.5) + 'px';
        pdfFrame.style.height = Math.floor(height * 1.5) + 'px';
        // page.moveTo(10, 20);
        // page.drawText('Hello World! \n Click me', { x: 10, y: 150, size: 8, lineHeight: 8, color: rgb(0.1, 0.2, 0.95), BlendMode: 'Overlay' });
        // const form = pdfDoc.getForm();
        // const oFontReader = new FileReader();
        // oFontReader.readAsArrayBuffer(timesFont);
        // const oFontREvent = await new Promise(resolve => oFontReader.onload = resolve);
        // const fontBytes = oFontREvent.target.result;
        const fontBytes = await fetch(timesFont).then((res) => res.arrayBuffer());
        const customFont = await pdfDoc.embedFont(fontBytes);
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        // page.setFont(timesRomanFont);

        // Create a string of text and measure its width and height in our custom font
        // const text = 'This is text!\n2ng row\n3ng row\n4ng row\n5rd long row'
        // let text = 'Signed ' + dateFormatRu.format(new Date()) + ' by Cert';
        let text = `Signed ${dateFormatRu.format(new Date())} by Cert`;
        text += '\ncert: ' + selectedCert.serial;
        text += `\nДействует: с ${selectedCert.from} по ${selectedCert.expire}`;
        text += '\nowner: ' + selectedCert.CN;
        if (selectedCert['ИНН ЮЛ']) text += `\n${selectedCert.T} ${selectedCert.SN} ${selectedCert.G}`;
        const textSize = 8
        const x = 10
        const y = 10
        const padding = textSize / 3;
        const textArray = text.split('\n');
        const textWidth = Math.max(...textArray.map(textRow => customFont.widthOfTextAtSize(textRow, textSize)));
        const textRowHeight = customFont.heightAtSize(textSize);
        const textHeight = textRowHeight * textArray.length;

        // Draw the string of text on the page
        page.drawText(text, {
          x: x + padding,
          y: y + padding + textHeight - textRowHeight + textRowHeight / 4,
          size: textSize,
          font: customFont,
          color: rgb(0.1, 0.2, 0.95),
          // maxWidth: 100,
          lineHeight: textRowHeight,
          // wordBreaks: ['text']
        })

        // Draw a box around the string of text
        page.drawRectangle({
          x: x,
          y: y,
          width: textWidth + padding * 2,
          height: textHeight + padding * 2,
          borderColor: rgb(0.1, 0.2, 0.95),
          borderWidth: 1,
        })
        // console.log(textRowHeight);
        // const button = form.createButton('some.button.field');
        // button.updateAppearances(helvetica);
        // page.setFontSize(4);
        // button.addToPage(' Do Stuff \n Click me ', page, { x: 10, y: 10 });
        // const textField = form.createTextField('best.gundam')
        // textField.setText('Exia')
        // textField.addToPage(page, {
        //   x: 50,
        //   y: 75,
        //   // width: 200,
        //   // height: 100,
        //   textColor: rgb(1, 0, 0),
        //   backgroundColor: rgb(0, 1, 0),
        //   borderColor: rgb(0, 0, 1),
        //   borderWidth: 2,
        // })
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        pdfFrame.src = pdfDataUri;
      }
      oFReader.readAsArrayBuffer(selectedFiles[0]);
    }
  }

  const onChangeCert = (ev, oCert) => {
    setSelectedCert(oCert);
    setHasCert(true);
    // console.log(oCert);
  }

  const fSelectFiles = aNewFiles => {
    // console.log(aNewFiles);
    setSelectedFiles(aNewFiles.length
      ? aNewFiles.reduce(
        (aFiles, oFile) => aFiles.concat(aFiles.find(f => f.name == oFile.name) ? [] : oFile),
        selectedFiles
      ) : []
    );
    setHasFile(!!aNewFiles.length);
  }

  const hDeleteChip = oFileToDelete => {
    setSelectedFiles(aFiles => aFiles.filter(oFile => oFile.name !== oFileToDelete.name));
  };

  const handleSubmit = ev => {
    ev.preventDefault();
    setSubmitOn(false);
    setLoading(true);
    fRun();
  };

  const [dragActive, setDragActive] = React.useState(false);

  const handleDrag = function(ev) {
    // console.log(ev.type);
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.type === "dragenter" || ev.type === "dragover") {
      setDragActive(true);
    } else if (ev.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragActive(false);
    // console.log(ev);
    if (ev.dataTransfer.files && ev.dataTransfer.files[0]) {
      fSelectFiles(Array.from(ev.dataTransfer.files));
    }
  };
  
  const fSetCertsList = async () => {
    await cadesplugin;
    const pStore = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");
    await pStore.Open(2, "My", 2);
    const oCertificates = await pStore.Certificates;
    const nCertsCount = await oCertificates.Count;
    const aCertsInfo = [];
    for (let i = 0; i < nCertsCount; i) {
      const oCertificate = await oCertificates.Item(++i);
      const oSubjectName = await oCertificate.SubjectName;
      const dFrom = await oCertificate.ValidFromDate;
      const dExpire = await oCertificate.ValidToDate;
      const oCertInfo = oSubjectName.split(', ').reduce((obj, pair) => {
        const [key, val] = pair.split('=');
        obj[key] = val && val.replace(/"/g, '');
        return obj;
      }, {});
      oCertInfo.nIndex = i;
      oCertInfo.nExpire = +new Date(dExpire);
      oCertInfo.bValidTime = +new Date(dExpire) > Date.now() && +new Date(dFrom) < Date.now();
      oCertInfo.oCert = oCertificate;
      oCertInfo.from = dateFormatRu.format(new Date(Date.parse(dFrom)));
      oCertInfo.expire = dateFormatRu.format(new Date(Date.parse(dExpire)));
      oCertInfo.serial = await oCertificate.SerialNumber;
      aCertsInfo.push(oCertInfo);
      // console.log(oCertificate);
    }
    // console.log(nCertsCount);
    setCertsList(aCertsInfo.sort((a, b) => b.nExpire - a.nExpire));
    await pStore.Close();
  };

  const renderOption = (props, option) => <Box component="li" {...props} key={option.serial}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{ my: 2 }}
    >
      <Typography component="h4" color={option.bValidTime ? 'primary' : 'red'} sx={{ mb: 1 }}>
        <CompanyIcon uric={option.O} />
        <b>{option.CN}</b>
      </Typography>
      <div>{option['ИНН ЮЛ'] && 'ИНН ЮЛ ' + option['ИНН ЮЛ']}</div>
      <div>{option.SN} {option.G}</div>
      <div>{option.T || null}</div>
      <div>{'ИНН ' + option['ИНН']}</div>
      <div>{'СНИЛС ' + option['СНИЛС']}</div>
      <div>{'Сертификат ' + option.serial}</div>
      <Box color={option.bValidTime ? 'black' : 'red'}>Действует с {option.from} по {option.expire}</Box>
    </Grid>
  </Box>

  const fRun = async () => {
    const zip = new JSZip();
    const oSigner = await cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
    await oSigner.propset_Certificate(selectedCert.oCert);
    await oSigner.propset_CheckCertificate(true);
    await oSigner.propset_TSAAddress("http://pki.tax.gov.ru/tsp/tsp.srf");

    Promise.all(selectedFiles.map(async oFile => {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(oFile);
      const oFREvent = await new Promise(resolve => oFReader.onload = resolve);
      const header = ";base64,";
      const sFileData = oFREvent.target.result;
      const sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);
      const oSignedData = await cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
      await oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
        await oSignedData.propset_Content(sBase64Data);
      try {
        const sSignedMessage = await oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_BES, bDetached);
        zip.file(oFile.name, sBase64Data, {base64: true});
        zip.file(oFile.name + '.sig', sSignedMessage);
      } catch (err) {
        throw(new Error("Не удалось создать подпись. Ошибка: " + cadesplugin.getLastError(err)));
      }
    }))
    .then(() => zip.generateAsync({type:"blob"}))
    .then(contentBlob => {
      let link = document.createElement('a');
      link.download = selectedFiles[0].name + '.zip';
      link.href = URL.createObjectURL(contentBlob);
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(fErrorHandler)
    .finally(() => {
      setSubmitOn(true);
      setLoading(false);
    });
  }

  useEffect(() => {
    fSetCertsList();
    new MutationObserver(mutationRecords => {
      const filesList = mutationRecords[0].target;
      if (filesList.id != 'filesList') return;
      let newHeight = filesList.offsetHeight;
      newHeight = newHeight ? newHeight + 16 + 'px' : 0;
      filesList.parentNode.style.height = newHeight;
      // console.log(filesList.offsetHeight);
    }).observe(document.getElementById('filesList'), {
      childList: true, // наблюдать за непосредственными детьми
    });
    window.addEventListener('dragenter', handleDrag)
    // window.addEventListener('mouseup', ev => {
    //   ev.preventDefault();
    //   ev.stopPropagation();
    //   console.log(ev);
    // })
  }, []);

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mx: 'auto', my: 0, width: 1, maxWidth: 400 }}
    >
      {dragActive && <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 20,
        }}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      ></Box>}
      <Box sx={{ mt: 5, backgroundColor: dragActive ? 'lightskyblue' : 'initial' }}>
        <MuiFileInput
          fullWidth
          multiple
          value={selectedFiles}
          onChange={fSelectFiles}
          label='Выберите файл(ы)'
          placeholder='Перетащите файл(ы) сюда или нажмите на это поле для выбора'
        />
        <Box sx={{
          overflow: 'hidden',
          transition: 'height 1s ease',
          height: 0,
        }}>
          <Stack spacing={2} id='filesList' sx={{ mt: 2 }}>{
            selectedFiles.map(oFile => selectedFiles.length > 1 && <Chip
              // icon={<AttachmentOutlinedIcon />}
              label={oFile.name}
              key={oFile.name}
              // variant="outlined"
              onDelete={ev => hDeleteChip(oFile)}
              sx={{
                height: 'auto',
                justifyContent: 'space-between',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                  p: 1
                },
              }}
            />)
          }</Stack>
        </Box>
      </Box>
      <Autocomplete
        fullWidth
        disableClearable
        id="combo-box-certs"
        options={certsList}
        onChange={onChangeCert}
        getOptionLabel={(option) => option.CN}
        renderOption={renderOption}
        renderInput={(params) => <TextField {...params}
          label="Выберите сертификат"
          error={!selectedCert.bValidTime}
          helperText={selectedCert.bValidTime ? '' : "Выбранный сертификат не действителен (истёк)"}
        />}
        sx={{ mt: 3 }}
      />
      <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 3 }}>
        <Typography>Прикреплённая</Typography>
        <Switch
          checked={bDetached}
          onChange={ev => setDetached(ev.target.checked)}
        />
        <Typography>Откреплённая</Typography>
      </Stack>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 4 }}
        className={loading ? 'crpt-load' : ''}
        disabled={!(bHasFile && bHasCert && submitOn && selectedCert.bValidTime)}
      >
        {loading ? '' : 'Подписать'}
      </Button>
      <Link
        color="inherit"
        href="https://e-trust.gosuslugi.ru/#/portal/sig-check"
        target="_blank"
        rel="noopener"
      >
        Ссылка для проверки подписи на Госуслугах
      </Link>
      <Box sx={{ display: process.env.REACT_APP_TEST ? 'block' : 'none' }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ my: 3 }}
          onClick={fTest}
        >
          Test
        </Button>
        <iframe id="pdf" style={{ width: '400px', height: '300px' }}></iframe>
      </Box>
    </Box>
  );
}

export default App;
